.hero
  &--wrapper
    height: 100vh
    height: var(--vh)
    @apply relative


  &--images,
  &--figure
    @apply relative z-10

  &--scroll-trigger
    cursor: url("/assets/images/arrow-down.svg") 16 16, pointer
  &--images *
    @apply h-full

  &--img
    @apply absolute top-0 left-0 w-full h-full object-cover
