.linkteaser
  &.has-link
    @apply cursor-pointer

  &--body
    &:after
      content: ''
      @apply absolute z-30
      @apply left-0 top-0 h-full w-full
      @apply bg-gradient-to-b from-transparent to-black_transparent
    &:before
      content: ''
      @apply absolute z-40
      @apply left-0 bottom-0 w-full h-2 md:h-4
      @apply bg-primary
      @apply transition-all

  &--link
    @apply relative z-50


  &.has-link
    &:hover
      .linkteaser--body
        &:before
          @apply h-full
