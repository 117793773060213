.header
  .base--header
    @apply pt-1.5 md:pt-4 lg:pt-6
    // @apply pb-2 md:pb-3 lg:pb-4
    @apply transition-all duration-300

  &-logo
    @apply relative z-[100]
    @apply w-[78px] md:w-[104px]
    @apply translate-y-[18px] lg:translate-y-6
    @apply transition-all duration-300

  &--navigation-wrapper
    @apply lg:pb-5

  &.has-scrolled
    .base--header
      @apply pt-0 lg:pt-0
    .header-logo
      @apply w-[64px] md:w-[78px]
      @apply translate-y-[14px] lg:translate-y-4


