.editor

  @apply text-primary
  @apply text-copy_sm md:text-copy_base

  h1,
  h2,
  h3,
  h4,
  .h1,
  .h2,
  .h3,
  .h4
    @apply text-copy_sm md:text-copy_base font-bold text-secondary
    @apply block mb-6 md:mb-8
    @apply before:content-["/"] before:mr-2

  p ~ h1,
  p ~ h2,
  p ~ h3,
  p ~ h4
    @apply mt-12

  &-small
    @apply text-copy_small_sm md:text-copy_small_base xl:text-copy_small_lg 2xl:text-copy_small_xl

  p,
  ol,
  ul
    @apply mb-[1em]
    &:last-child
      @apply mb-0

  a
    @apply underline hover:text-neutral_light transition-colors

  ol,
  ul
    @apply max-w-3xl mx-auto text-left

  ul
    li
      @apply mb-3 pl-8 lg:pl-10 relative
      &:before
        content: '— '
        @apply absolute left-0
