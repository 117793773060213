@font-face {
  font-family: "Akkurat";
  src: url("/assets/fonts/AkkuratLLWeb-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Akkurat";
  src: url("/assets/fonts/AkkuratLLWeb-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Akkurat";
  src: url("/assets/fonts/AkkuratLLWeb-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
