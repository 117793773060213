.navigation

  &--trigger
    @apply w-16 h-16 absolute z-30 top-0
    @apply -translate-y-0.5 md:translate-y-0.5
    z-index: 100
    border: none
    background: none
    padding: 0
    outline: none !important

    @apply text-current

    &-deco-01,
    &-deco-02,
    &-deco-03,
    &-deco-04
      @apply content-['']
      @apply block w-6 h-px md:h-[2px]
      @apply absolute top-1/2 left-1/2 -translate-x-1/2
      @apply transition-all duration-200 origin-center
      @apply bg-current

    &-deco-01
      @apply rotate-0
      @apply -translate-y-1.5 md:-translate-y-1.5
    &-deco-02
      @apply rotate-0
      @apply translate-y-1.5 md:translate-y-1.5
    &-deco-03
      @apply rotate-0
    &-deco-04
      @apply rotate-0

    .navigation__active &
      &-deco-01
        @apply opacity-0 -translate-y-4
      &-deco-02
        @apply opacity-0 translate-y-4
      &-deco-03
        @apply rotate-45
      &-deco-04
        @apply -rotate-45


  &--header
    @apply opacity-0 invisible
    @apply lg:opacity-100 lg:visible
    .navigation__active &
      @apply opacity-100 visible

    .navigation--link
      @apply font-light lg:font-normal lg:hover:text-neutral_light transition-colors
    .navigation--link.is-active
      // @apply font-normal lg:font-bold
      @apply font-bold lg:hover:text-primary

  &--header-item
    @apply opacity-100 translate-y-0
    transition: opacity 0.5s
    .fade-out &
      @apply opacity-0
